import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

// configure firebase
const firebaseConfig = {
  apiKey: "AIzaSyAxIff-t1cHybeEClxmPKGDk7QazOa9bwM",
  authDomain: "clinic-portal-1776e.firebaseapp.com",
  projectId: "clinic-portal-1776e",
  storageBucket: "clinic-portal-1776e.appspot.com",
  messagingSenderId: "798497993881",
  appId: "1:798497993881:web:b8ec2b394e16f427cb7408"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;