import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route} from "react-router-dom";
import Login from './Pages/LoginPage/Login';
import ResetPassword from './Pages/LoginPage/ResetPassword';
// import EditProfile from './Pages/EditProfile/EditProfile';
import Home from './Pages/Home/Home';
import EditProfile from './Pages/EditProfile/EditProfile'
import Patients from './Pages/Patients/Patients';
// import AddNewPatient from './Components/Patients/AddNewPatient';
import SideNavigationBar from './Components/SideNavigationBar/SideNavigationBar';
// import PageNotFound from './Pages/PageNotFound/PageNotFound';

class App extends Component {
  render() {
    return (
      <Router basename={process.env.PUBLIC_URL}>
        <Fragment>
          <Route exact path="/" component={Login} />
          <Route exact path="/Login" component={Login} />
          <Route exact path="/ResetPassword" component={ResetPassword} />
          <Route path="/account/:accountKey" component={SideNavigationBar}/>
          <Route exact path="/account/:accountKey/Home" component={Home} />
          <Route exact path="/account/:accountKey/EditProfile" component={EditProfile} />
          <Route exact path="/account/:accountKey/Patients" component={Patients} />
          {/* <Route exact path="/Patients/AddNewPatients" component={AddNewPatient} /> */}
          {/* <Route  component={PageNotFound}/> */}
        </Fragment>
      </Router>
    )
  }
}

export default App;
