import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import firebase from "../../Components/Database/firebase";
import logoImage from './../../images/logoImage.png'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import './../../index.css'
import './LoginStyles.css'
import './LoginResponsive.css'
const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

// import ForgotPassword from './ForgotPassword';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      db: [],
      accountKey: '',
      loggedIn: '',
      attemptedLogin: false,
      passwordShow: false,
      eyeShow: false,
      eyeSlashShow: true
    }
  }

  componentDidMount() {
    // const key 
    const dbRef = firebase.database().ref();
    dbRef.on('value', (snapshot) => {
      const data = snapshot.val()
      const dbArray = [];

      // turn db object into an array to loop through
      for (let key in data) {
        dbArray.push({
          key: key,
          data: data[key]
        })
      }

      // store db array in state
      this.setState({
        db: dbArray
      })
    })
  }

  // sets email state value to typed email value
  handleEmail = (event) => {
    this.setState({
      email: event.target.value
    })
  }

  // sets paassword state value to typed paassword value
  handlePassword = (event) => {
    this.setState({
      password: event.target.value
    })
  }

  // login event
  handleLogin = (event) => {
    event.preventDefault();

    // run username & password match test
    this.handleMatch()

    // track login attempt for errors
    if (this.handleMatch !== true) {
      this.handleAttempt()
    }
  }

  // check if username and password are correct
  handleMatch = () => {
    const email = this.state.email
    const password = this.state.password
    const dbArray = this.state.db
    for (let i = 0; i < dbArray.length; i++) {
      const account = dbArray[i].data.clinicAccount
      if (account.clinicUsername === email && account.clinicPassword === password) {
        this.setState({
          accountKey: dbArray[i].key,
          loggedIn: true,
        }, () => {
          window.sessionStorage.setItem(dbArray[i].key, true)
        })
        return true
      }
    }
  }

  // if username and/or password is incorrect
  handleAttempt = () => {
    if (this.state.loggedIn === '') {
      this.setState({
        attemptedLogin: true
      })
    }
  }

  togglePasswordShow = () => {
    if (this.state.passwordShow === false) {
      this.setState({
        passwordShow: !this.state.passwordShow,
        eyeShow: !this.state.eyeShow,
        eyeSlashShow: !this.state.eyeSlashShow
      })
    } else {
      this.setState({
        passwordShow: false,
        eyeShow: false,
        eyeSlashShow: true
      })
    }
  }



  render() {
    const accountKey = this.state.accountKey
    if(this.state.loggedIn === true) {
      return  <Redirect  to={`/account/${accountKey}/Patients`} />
    }
    return (
      <div className="LoginPageWrapper">
        <main className="LoginCard Card">
          <img className="LoginLogo" alt="Novela logo" src={logoImage} />

          <h1 className="LoginInstruction">Please log in to your account!</h1>

          <p className={this.state.loggedIn === '' && this.state.attemptedLogin === true ? 'loginError show' : 'loginError'}>The email address or the password is invalid.</p>

        <form 
          action="/"
          onSubmit={this.handleLogin}
          className="LoginForm"
          >
            <section className="LoginInputWrap LoginEmail">
              <label htmlFor='email'>Email Address</label>
              <input 
              onChange={this.handleEmail} 
              value={this.state.email} 
              type="email" 
              id="email" 
              placeholder="Email" 
              maxLength="30"
              required />
            </section>
            
          <section className="LoginInputWrap LoginPassword">
            <label htmlFor='password'>Password</label>
            <input 
            onChange={this.handlePassword} 
            value={this.state.password} 
            type={this.state.passwordShow === true ? 'text' : 'password'} 
            id="password" 
            placeholder="Password" 
            maxLength="30" 
            required/>
            {this.state.eyeShow ?
            <i className="ShowPasswordIcon" onClick={this.togglePasswordShow}>{eye}</i> : null }
            {this.state.eyeSlashShow ?
            <i className="ShowPasswordIcon" onClick={this.togglePasswordShow}>{eyeSlash}</i> : null }
          </section>
            
            {/* <section className="ResetPassword">
              <Link to={'/ResetPassword'} style={{ textDecoration: 'none', color: '#6d6080' }}>Reset Password</Link>
            </section> */}
            
            <button className="SignInButton" onClick={this.handleLogin}>LOG IN</button>

          </form>
        </main>
      </div>
    )
  }
}

export default Login;