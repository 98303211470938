import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './../../index.css'
import './ResetPasswordStyles.css'


class ResetPassword extends Component {
  constructor() {
    super();
    this.state = {

    }
  }

  componentDidMount() {
    // const key 
    // firebase call
  }

  render () {
    return (
      <div className="ResetPasswordWrapper">
        <main className="ResetPasswordCard Card">
          <h1>Reset Password</h1>
          <p>Submit the email address attached to your account.</p>
          <p>An email with a password reset link will be sent out shortly.</p>
          <form className="ResetPasswordForm">
            <section className="ResetPasswordInputWrap ResetPasswordEmail">
              <label htmlFor='email'>Email Address</label>
              <input 
              onChange={this.handleEmail} 
              value={this.state.email} 
              type="email" 
              id="email" 
              placeholder="Email" 
              maxLength="30"
              required/>
            </section>
            
            <button className="ResetPasswordButton">SEND PASSWORD RESET EMAIL</button>

          </form>

          <section className="BackToLogin">
            <Link to={'/Login'} style={{ textDecoration: 'none', color: '#6d6080' }}>Back to Login Page</Link>
            {/* can change button to input, button for testing purposes without need for styles */}
          </section>

        </main>
        
      </div>
    )
  }
}

export default ResetPassword;