import React, {Component} from "react";
import { Link } from "react-router-dom";
import {Redirect} from 'react-router-dom';
import logoImage2 from './../../images/logoImage2.png'
import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes, faEye, faEyeSlash, faCheck, faSignOutAlt, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import firebase from "../../Components/Database/firebase";

import './../../index.css'
import './NavigationStyles.css'
import './NavigationResponsive.css'
const exit = <FontAwesomeIcon icon={faSignOutAlt} />;
const burger = <FontAwesomeIcon icon={faBars} />;
const close = <FontAwesomeIcon icon={faTimes} />;
const ExLink = <FontAwesomeIcon icon={faExternalLinkAlt} />;

// const eye = <FontAwesomeIcon icon={faEye} />;
// const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

class SideNavigationBar extends Component {
  constructor() {
    super();
    this.state = {
      loggedIn: true,
      clinicAccount: {
        clinicPassword: '',
        clinicUsername: ''
      },
      jotFormLink: '',
      formDivShow: false,
      linkCopied: false,
      handleChangePassword: false,
      currentPassword: '',
      currentPasswordShow: false,
      newPassword: '',
      newPasswordShow: false,
      confirmNewPassword: '',
      confirmNewPasswordShow: false,
      passwordError: '',
      Success: '',
      mobileMenu: false,
    }
  }

  componentDidMount() {
    const key = this.props.match.params.accountKey
    this.handleLoggedOut(key)

    const dbRef = firebase.database().ref(`${key}/clinicAccount`);
    dbRef.on('value', (snapshot) => {
      this.setState({
        clinicAccount: snapshot.val()
      })
    })
    const dbFormRef = firebase.database().ref(`${key}/clinicInfo/jotForm`);
    dbFormRef.on('value', (snapshot) => {
      this.setState({
        jotFormLink: snapshot.val()
      })
    })
    
  }

  handleLogout = (e) => {
    e.preventDefault()
    const key = this.props.match.params.accountKey
    window.sessionStorage.removeItem(key);
    this.handleLoggedOut(key)
  }

  handleLoggedOut = (key) => {
    const storageItem = window.sessionStorage.getItem(key);
    if (storageItem !== 'true') {
      this.setState({
        loggedIn: false
      })
    }
  }

  submitChangePassword = (e) => {
    e.preventDefault()
    console.log('submitted')
    console.log(this.state.clinicAccount.clinicPassword)
    const key = this.props.match.params.accountKey

    if (this.state.clinicAccount.clinicPassword !== this.state.currentPassword) {
      this.setState({
        passwordError: 'incorrect password',
      })
      setTimeout(this.removePasswordMssgError, 3000)
    } else if (this.state.newPassword !== this.state.confirmNewPassword) {
      this.setState({
        passwordError: 'passwords not matching',
      })
      setTimeout(this.removePasswordMssgError, 3000)
    } else if (this.state.clinicAccount.clinicPassword === this.state.currentPassword && this.state.newPassword === this.state.confirmNewPassword) {
      const dbRef = firebase.database().ref(`${key}/clinicAccount`);
      let newData = {
          clinicPassword: this.state.newPassword,
          clinicUsername: this.state.clinicAccount.clinicUsername
      }
      dbRef.update(newData)
      this.setState({
        passwordSuccess: 'password change success'
      })
      setTimeout(this.removePasswordMssgSuccess, 3000)
    }
    this.setState({
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    })
  }

  removePasswordMssgError = () => {
    this.setState({
        passwordError: ''
    })
  }
  removePasswordMssgSuccess = () => {
    this.setState({
        passwordSuccess: ''
    })
  }

  handleChangePassword = () => {
    this.setState({
      handleChangePassword: !this.state.handleChangePassword,
      currentPassword: '',
      currentPasswordShow: false,
      newPassword: '',
      newPasswordShow: false,
      confirmNewPassword: '',
      confirmNewPasswordShow: false,
      passwordError: ''
    })
    document.getElementById('passwordChangeForm').reset()
    if (this.state.mobileMenu === true) {
      this.setState({
        mobileMenu: !this.state.mobileMenu
      })
    }
  }

  handleFormMenu = (e) => {
    e.preventDefault()
    this.setState({
      formDivShow: !this.state.formDivShow
    })
  }

  copyLink = () => {
    const copyUrl = document.getElementById('formLink')
    copyUrl.select()
    // for mobile
    copyUrl.setSelectionRange(0, 99999)
    document.execCommand("copy")
    copyUrl.blur();
    this.setState({
        linkCopied: true
    })
    setTimeout(this.removeMssg, 3000)
}
removeMssg = () => {
  this.setState({
      linkCopied: false
  })
}

  handleChange = (e) => {
    switch (e.target.id) {
      case "currentPassword":
        this.setState({
          currentPassword: e.target.value
        })
        break;
      case "newPassword": 
        this.setState({
          newPassword: e.target.value
        })
        break;
      case "confirmPassword":
        this.setState({
          confirmNewPassword: e.target.value
        })
        break;
      default:
        break;
    }
  }

  showConfirmationPassword = () => {
    this.setState({
      confirmNewPasswordShow: !this.state.confirmNewPasswordShow
    })
  }
  showCurrentPassword = () => {
    this.setState({
      currentPasswordShow: !this.state.currentPasswordShow
    })
  }
  showNewPassword = () => {
    this.setState({
      newPasswordShow: !this.state.newPasswordShow
    })
  }

  handleMenu = () => {
    this.setState({
      mobileMenu: !this.state.mobileMenu
    })
  }

  render() {
    const key = this.props.match.params.accountKey
    if(this.state.loggedIn === false) {
      return  <Redirect  to={`/`} />
    }
    return (
      <div>
        <nav className="MobileNav">
          <div className="MobileNavTopBar">
            <img className="MobileNavLogo" alt="Novela logo" src={logoImage2} />
            <div className="MobileMenuIconsBox">
              <button className={this.state.mobileMenu === false ? "MobileMenuIcon BurgerIcon show" : "MobileMenuIcon BurgerIcon"} onClick={this.handleMenu}>{burger}</button>
              <button className={this.state.mobileMenu === false ? "MobileMenuIcon BurgerIcon" : "MobileMenuIcon BurgerIcon show"} onClick={this.handleMenu}>{close}</button>
            </div>
          </div>

          <div className="Overlay">
            <ul className={this.state.mobileMenu === false ? "MobileMenu" : "MobileMenu show"}>
              <li>
                <NavLink to={`/account/${key}/Patients`} 
                onClick={this.handleMenu}
                activeClassName="active"
                className="MobileMenuLinks PatientsLink">Patients</NavLink>
              </li>
              <li>
                <NavLink to={`/account/${key}/Home`}
                onClick={this.handleMenu}
                activeClassName="active"
                className="MobileMenuLinks">Profile</NavLink>
              </li>
              <li>
                <a onClick={this.handleFormMenu}
                activeClassName="active"
                className="MobileMenuLinks">Signup Form <span>{ExLink}</span></a>
              </li>
              <li>
                <a 
                href="https://www.youtube.com/playlist?list=PLWCCGYs3He7XCQnbhOwR3aDun9FQTGpPF"
                target="_blank"
                rel="noopener noreferrer"
                activeClassName="active"
                className="MobileMenuLinks">Training Videos <span>{ExLink}</span></a>
              </li>
                <li>
                  <a onClick={this.handleChangePassword}
                  // href=""
                  activeClassName="active"
                  className="MobileMenuLinks changePassword">Change Password</a>
                </li>
              <li>
                <button
              className="MobileMenuLinks MobileLogoutButton"
              onClick={this.handleLogout}>
                Log out
              </button>
                </li>
            </ul>
          </div>
        </nav>
      
        <nav className="DesktopNav">
          <img className="NavLogo" alt="Novela logo" src={logoImage2} />
          <ul className="Menu">
            <li>
              <NavLink to={`/account/${key}/Patients`} 
              activeClassName="active"
              className="MenuLinks PatientsLink">Patients</NavLink>
            </li>
            <li>
              <NavLink to={`/account/${key}/Home`}
              activeClassName="active"
              className="MenuLinks HomeLink">Profile</NavLink>
            </li>
            <li>
              <a onClick={this.handleFormMenu}
              activeClassName="active"
              className="MenuLinks">Signup Form</a>
            </li>
            <li>
                <a 
                href="https://www.youtube.com/playlist?list=PLWCCGYs3He7XCQnbhOwR3aDun9FQTGpPF"
                target="_blank"
                rel="noopener noreferrer"
                activeClassName="active"
                className="MenuLinks">Training Videos <span>{ExLink}</span></a>
            </li>
            <li>
              <a onClick={this.handleChangePassword}
              // href=""
              activeClassName="active"
              className="MenuLinks">Change Password</a>
            </li>
            <li>
              <button
            className="LogoutButton"
            onClick={this.handleLogout}>
              {exit}
              <span>Log out</span>
            </button>
            </li>
          </ul>

            
        </nav>
{/* //////////////////////////////////////////////////////////////////////////// */}
        <div className={this.state.formDivShow === false ? "jotFormDiv" : "jotFormDiv show" }>
          <div className="SignupFormContent">
            <p>Copy this link to send form to your patients.</p>
            <p className={this.state.linkCopied === true? "copiedLink show" : "copiedLink"}>Copied to clipboard!</p>
            <input type="text" id="formLink" value={this.state.jotFormLink}/>
            <button onClick={this.copyLink}>Copy Link</button>
            <a 
            href={this.state.jotFormLink} 
            target="_blank"
            rel="noopener noreferrer">Go to Form<span>{ExLink}</span></a>
            <FontAwesomeIcon className="changePasswordCancel" icon={faTimes} onClick={this.handleFormMenu}/>
          </div>
        </div>
{/* //////////////////////////////////////////////////////////////////////////// */}
        <div className={this.state.handleChangePassword === false ? "changePasswordContainer" : "changePasswordContainer show" }>
          <div className="changePasswordContent">
            <h2>Change Password</h2>
            <div className="changePasswordError">
              {this.state.passwordError === 'passwords not matching' ? 
                <p>Your Passwords do not match</p> : null
              }
              {this.state.passwordError === 'incorrect password' ? 
                <p>Incorrect Password</p> : null
              }
            </div>
            <div className="changePasswordSuccess">
              {this.state.passwordSuccess === 'password change success' ? 
                <p>Password changed successfully!</p> : null
              }
            </div>
            <form 
            id="passwordChangeForm"
            action="/"
            onSubmit={this.submitChangePassword}>
              <div>
                <label className="changePassLabel" htmlFor="currentPassword">Current Password</label>
                <input
                  required
                  value={this.state.currentPassword}
                  type={this.state.currentPasswordShow === true ? 'text' : 'password'}
                  id="currentPassword"
                  placeholder="Current Password"
                  onChange={this.handleChange} 
                />
                <FontAwesomeIcon className="passChangeShowIcon" onClick={this.showCurrentPassword} icon={this.state.currentPasswordShow === true ? faEye : faEyeSlash}/>
              </div>
              <div>
                <label className="changePassLabel" htmlFor="newPassword">New Password</label>
                <input
                  required
                  value={this.state.newPassword}
                  type={this.state.newPasswordShow === true ? 'text' : 'password'}
                  id="newPassword"
                  placeholder="New Password"
                  onChange={this.handleChange} 
                />
                <FontAwesomeIcon className="passChangeShowIcon" onClick={this.showNewPassword} icon={this.state.newPasswordShow === true ? faEye : faEyeSlash}/>
              </div>
              <div>
                <label className="changePassLabel" htmlFor="confirmPassword">Confirm New Password</label>
                <input
                  required
                  value={this.state.confirmNewPassword}
                  type={this.state.confirmNewPasswordShow === true ? 'text' : 'password'}
                  id="confirmPassword"
                  placeholder="Confirm New Password"
                  onChange={this.handleChange} 
                />
                <FontAwesomeIcon className="passChangeShowIcon" onClick={this.showConfirmationPassword} icon={this.state.confirmNewPasswordShow === true ? faEye : faEyeSlash}/>
              </div>
              <div>
                <button className="ProfileSaveButton changePasswordSave" type="submit"><FontAwesomeIcon className="CheckButton" icon={faCheck} /><span> </span>Save Changes</button>
              </div>
            </form>
            <FontAwesomeIcon className="changePasswordCancel" icon={faTimes} onClick={this.handleChangePassword}/>
          </div>
        </div>
{/* //////////////////////////////////////////////////////////////////////////// */}
        
      </div>
    )
  }
} 

export default SideNavigationBar;
