// patients page
import React, {Component} from "react";
// import Patient from '../../Components/Patients/Patient'
// import { Link } from "react-router-dom";
import './../../index.css'
import './PatientsStyles.css'
import './PatientsResponsive.css'
import logoImage from './../../images/logoImage.png'
// import AddNewPatient from './AddNewPatient';
import firebase from "../../Components/Database/firebase";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faTimes, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
const ExLink = <FontAwesomeIcon icon={faExternalLinkAlt} />;

class Patients extends Component {
  constructor() {
    super();
    this.state = {
      clinic: {
        clinicPatients: [],
      },
      calendar: '',
    }
  }

  componentDidMount() {
    const key = this.props.match.params.accountKey
    const dbRef = firebase.database().ref(key);
    dbRef.on('value', (snapshot) => {
      const data = snapshot.val();
      const patientsArray = []
      const patientData = data.clinicPatients

      // turn database object into array to map over
      for (let key in patientData) {
        patientsArray.push({
          key: key,
          patientData: patientData[key]
        });
      }

      // replace placeholder state with array from database
      this.setState({
        clinic: {
          clinicPatients: patientsArray
        }
      })
    })

    // clear cache in patient portal domain
    // document.getElementById('patientLink').contentWindow.postMessage('test', "*")
  }

  handlePatientLink = (e) => {
    e.preventDefault()
    let url = e.target.id
    if (url !== 'none') {
      window.open(url, "Patient Portal");
    }
  }

  handleCalendar = (e) => {
      this.setState({
        calendar: e.target.id
      })
  }
  closeCalendar = () => {
    this.setState({
      calendar: ''
    })
  }


  

  render() {
    const patients = this.state.clinic.clinicPatients
    return (

      <div className="PatientsListWrap">
        <div className="PatientsListCard Card">
          <div className="PatientsHeaderContainer">
            <div className="H1Container">
            <div className="HeaderDecor"></div>
            <h1 className="PatientHeader">Patients</h1>
          </div>
          <img className="Logo" alt="Novela logo" src={logoImage} />
          </div>
          
          
          {/* <div className="SearchBarContainer">
            <input
            className="searchBar"
            type="text"
            id="searchBar"
            placeholder="Search...">
            </input>
          </div> */}
          <div className="TableContainer">
            <div className="TableLabels">
            <p>Patient Name</p>
            <p>Patient ID</p>
            <p>Enrollment Date</p>
            <p>Monthly Report</p>
          </div>
          {patients.map((patientObject) => {
            let initialFirst = patientObject.patientData.patientNameFirst[0]
            let initialLast = patientObject.patientData.patientNameLast[0]
            return (
              <div className="TableWrap">
                <ul key={patientObject.key} className="IndividualPatient PatientsTable">
                  <li className="PatientInitialsContainer">
                    <div className="PatientInitialsCircle">
                      <p className="PatientInitials">{initialFirst}{initialLast}</p>
                    </div>
                  </li>
                <li className="patientNameContainer">
                  <p className="PatientName" onClick={this.handlePatientLink} id={patientObject.patientData.portalLink}>
                    {patientObject.patientData.patientNameFirst} {patientObject.patientData.patientNameLast}<span>{ExLink}</span>
                  </p>
                </li>
                  <li className="PatientNumberContainer">
                    <p>{patientObject.patientData.patientId}</p>
                  </li>
                <li className="PatientStartDateContainer">
                  <p>{patientObject.patientData.startDate}</p>
                </li>
                <li className="PatienReports">
                  <button onClick={this.handleCalendar} id={`${patientObject.key}`} className="calendarButton">
                    <FontAwesomeIcon className="calendarIcon" icon={faCalendarAlt} />
                  </button>
                  <ul className={this.state.calendar === patientObject.key ? 'Calendar show' : 'Calendar'}>
                    <p className="calendarYear">2021</p>
                    <FontAwesomeIcon className="closeCalendarIcon" icon={faTimes} onClick={this.closeCalendar}/>
                    <a href={patientObject.patientData.pdfs.Jan} target="_blank" rel="noopener noreferrer"  className={patientObject.patientData.pdfs.Jan === undefined ? 'noLink' : 'hasLink'} download>Jan</a>
                    <a href={patientObject.patientData.pdfs.Feb} target="_blank" rel="noopener noreferrer" className={patientObject.patientData.pdfs.Feb === undefined ? 'noLink' : 'hasLink'} download>Feb</a>
                    <a href={patientObject.patientData.pdfs.Mar} target="_blank" rel="noopener noreferrer" className={patientObject.patientData.pdfs.Mar === undefined ? 'noLink' : 'hasLink'} download>Mar</a>
                    <a href={patientObject.patientData.pdfs.Apr} target="_blank" rel="noopener noreferrer" className={patientObject.patientData.pdfs.Apr === undefined ? 'noLink' : 'hasLink'} download>Apr</a>
                    <a href={patientObject.patientData.pdfs.May} target="_blank" rel="noopener noreferrer" className={patientObject.patientData.pdfs.May === undefined ? 'noLink' : 'hasLink'} download>May</a>
                    <a href={patientObject.patientData.pdfs.Jun} target="_blank" rel="noopener noreferrer" className={patientObject.patientData.pdfs.Jun === undefined ? 'noLink' : 'hasLink'} download>Jun</a>
                    <a href={patientObject.patientData.pdfs.Jul} target="_blank" rel="noopener noreferrer" className={patientObject.patientData.pdfs.Jul === undefined ? 'noLink' : 'hasLink'} download>Jul</a>
                    <a href={patientObject.patientData.pdfs.Aug} target="_blank" rel="noopener noreferrer" className={patientObject.patientData.pdfs.Aug === undefined ? 'noLink' : 'hasLink'} download>Aug</a>
                    <a href={patientObject.patientData.pdfs.Sep} target="_blank" rel="noopener noreferrer" className={patientObject.patientData.pdfs.Sep === undefined ? 'noLink' : 'hasLink'} download>Sep</a>
                    <a href={patientObject.patientData.pdfs.Oct} target="_blank" rel="noopener noreferrer" className={patientObject.patientData.pdfs.Oct === undefined ? 'noLink' : 'hasLink'} download>Oct</a>
                    <a href={patientObject.patientData.pdfs.Nov} target="_blank" rel="noopener noreferrer" className={patientObject.patientData.pdfs.Nov === undefined ? 'noLink' : 'hasLink'} download>Nov</a>
                    <a href={patientObject.patientData.pdfs.Dec} target="_blank" rel="noopener noreferrer" className={patientObject.patientData.pdfs.Dec === undefined ? 'noLink' : 'hasLink'} download>Dec</a>
                  </ul>
                </li>
              </ul>
              <div className="LineBreak PatientLine"></div>
              </div>
              
            )})}
          </div>
          
        
        {/* <Link to={`/Patients/AddNewPatients`}>Add new patient</Link> */}

      </div>
      </div>
      
    )
  }
}

export default Patients;