import React, { Component } from "react";
import firebase from "../../Components/Database/firebase";
import {Link, Redirect} from 'react-router-dom';
// import CENLogo from './../../images/ClinicIcons/CENLogo.jpg';
// import MidAtlanticLogo from './../../images/ClinicIcons/MidAtlanticLogo.JPG';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faPencilAlt, faMapMarkerAlt, faPhone, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';
// import { faTimes } from '@fortawesome/free-solid-svg-icons';
// import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import './../../index.css'
import './HomeStyles.css'
import './HomeResponsive.css'
const pencil = <FontAwesomeIcon icon={faPencilAlt} />;

class Home extends Component {
  constructor() {
    super();
    this.state = {
      clinic: {
        clinicInfo: {
          accountNumber: '',
          clinicAddress: '',
          clinicEmail: '',
          clinicName: '',
          phoneNumber: '',
          clinicLogo: '',
          clinicImg: ''
        }
      },
      user: {
        clinicUserInfo: {
          userEmail: '',
          userFirst: '',
          userLast: '',
          userPhoneNumberAreaCode: '',
          userPhoneNumberFirstThree: '',
          userPhoneNumberLastFour: ''
        },
        loggedIn: true
      },
      // editButtonShow: true,
      // editFormShow: false,
      editNameShow: false,
      editEmailShow: false,
      editNumberShow: false,
      editUserEmail: '',
      editUserFirst: '',
      editUserLast: '',
      editUserPhoneNumberAreaCode: '',
      editUserPhoneNumberFirstThree: '',
      editUserPhoneNumberLastFour: ''
    }
  }

  componentDidMount() {
    const key = this.props.match.params.accountKey
    firebase.database().ref(key).on("value", (snapshot) => {
      this.setState({
        clinic: snapshot.val(),
        user: snapshot.val()
      })
    })
    this.handleLoggedOut(key)
  }

  handleLoggedOut = (key) => {
    const storageItem = window.sessionStorage.getItem(key);
    if (storageItem !== 'true') {
      this.setState({
        loggedIn: false
      })
    }
  }
  
  // toggleEdit = () => {
  //   this.setState({
  //     editButtonShow: false,
  //     editFormShow: true
  //   })
  // }
  toggleNameEdit = () => {
    this.setState({
      editNameShow: true,
    })
  }
  toggleEmailEdit = () => {
    this.setState({
      editEmailShow: true,
    })
  }
  toggleNumberEdit = () => {
    this.setState({
      editNumberShow: true,
    })
  }

  // submit = (e) => {
  //   e.preventDefault();
  //   const key = this.props.match.params.accountKey

  //   const dbRef = firebase.database().ref(`${key}/clinicUserInfo`);
  //   let newData = {} 
  //   newData = {
  //     userEmail: this.state.editUserEmail,
  //     userFirst: this.state.editUserFirst,
  //     userLast: this.state.editUserLast,
  //     userPhoneNumberAreaCode: this.state.editUserPhoneNumberAreaCode,
  //     userPhoneNumberFirstThree: this.state.editUserPhoneNumberFirstThree,
  //     userPhoneNumberLastFour: this.state.editUserPhoneNumberLastFour
  //   }

  //   dbRef.update(newData);

  //   this.setState({
  //     editButtonShow: true,
  //     editFormShow: false
  //   })
  // }

  submitNameEdit = (e) => {
    e.preventDefault()
    const key = this.props.match.params.accountKey

    const dbRef = firebase.database().ref(`${key}/clinicUserInfo`);
    let newData = {} 
    newData = {
      userFirst: this.state.editUserFirst,
      userLast: this.state.editUserLast,
    }
    dbRef.update(newData);

    this.setState({
      editNameShow: false,
    })
  }
  submitEmailEdit = (e) => {
    e.preventDefault()
    const key = this.props.match.params.accountKey

    const dbRef = firebase.database().ref(`${key}/clinicUserInfo`);
    let newData = {} 
    newData = {
      userEmail: this.state.editUserEmail,
    }
    dbRef.update(newData);

    this.setState({
      editEmailShow: false,
    })
  }
  submitNumberEdit = (e) => {
    e.preventDefault()
    const key = this.props.match.params.accountKey

    const dbRef = firebase.database().ref(`${key}/clinicUserInfo`);
    let newData = {} 
    newData = {
      userPhoneNumberAreaCode: this.state.editUserPhoneNumberAreaCode,
      userPhoneNumberFirstThree: this.state.editUserPhoneNumberFirstThree,
      userPhoneNumberLastFour: this.state.editUserPhoneNumberLastFour
    }
    dbRef.update(newData);

    this.setState({
      editNumberShow: false,
    })
  }

  handleChange = (e) => {
    switch (e.target.id) {
      case "firstName":
        this.setState({
          editUserFirst: e.target.value,
        });
        break;
        case "editLastName":
        this.setState({
          editUserLast: e.target.value,
        });
        break;
      case "editEmail":
        this.setState({
          editUserEmail: e.target.value,
        });
        break;
      case "editAreaCode":
        this.setState({
          editUserPhoneNumberAreaCode: e.target.value,
        });
        break;
      case "editFirstThreeDigits":
        this.setState({
          editUserPhoneNumberFirstThree: e.target.value,
        });
        break;
      case "editLastFourDigits":
        this.setState({
          editUserPhoneNumberLastFour: e.target.value
        });
        break;
      default:
        break;
    }
  };

  // cancel = () => {
  //   this.setState({
  //     editButtonShow: true,
  //     editFormShow: false,
  //   })
  // }
  cancelNameEdit = () => {
    this.setState({
      editNameShow: false,
      editUserFirst: '',
      editUserLast: '',
    })
  }
  cancelEmailEdit = () => {
    this.setState({
      editEmailShow: false,
      editUserEmail: '',
    })
  }
  cancelNumberEdit = () => {
    this.setState({
      editNumberShow: false,
      editUserPhoneNumberAreaCode: '',
      editUserPhoneNumberFirstThree: '',
      editUserPhoneNumberLastFour: ''
    })
  }

  render() {
    const clinicInfo = this.state.clinic.clinicInfo
    if(this.state.loggedIn === false) {
      return  <Redirect  to={`/`} />
    }
    const clinicUserInfo = this.state.user.clinicUserInfo
    let clinicInitials = this.state.clinic.clinicInfo.clinicName[0]
    return (
        <div>
          <main className="ClinicInfoWrap">
            <div className="ClinicInfoCard Card">
              <h1 className="ClinicInfoHeader">{clinicInfo.clinicName}</h1>
              <div className="ClinicInfoContainer">
                
                {/* <div className="ClinicInfoColumn1">
                  <img className="ClinicLogo" alt="Your Clinic Logo" src={clinicIconAppletree} />
                </div> */}
                <div className="ClinicInfoColumn1 clinicInitials">
                  <div className="ClinicLogoContainer">
                    {this.state.clinic.clinicInfo.clinicImg === "empty" ? 
                      <p className="clinicLogoInit">{clinicInitials}</p> 
                    :
                      <img className="ClinicLogo" alt="Your Clinic Logo" src={this.state.clinic.clinicInfo.clinicImg} /> 
                    }
                  </div>
                </div>
                
                <div className="ClinicInfoColumn2">

                  <section className="ClinicInfoItems ClinicNumber">
                    <h3>Novela Account Number:</h3>
                    <p>{clinicInfo.accountNumber}</p>
                  </section>

                  <section className="ClinicInfoItems">
                    <h3 className="profileHeader">Info:</h3>
                  </section>

                  <section className="ClinicInfoItems ClinicAddress">
                    <p><span><FontAwesomeIcon className="profileIcon" icon={faMapMarkerAlt} /></span>{clinicInfo.clinicAddress}</p>
                  </section>

                  <section className="ClinicInfoItems ClinicPhoneNumber">
                    <p><span><FontAwesomeIcon className="profileIcon" icon={faPhone} /></span>{clinicInfo.phoneNumber}</p>
                  </section>

                  <section className="ClinicInfoItems ClinicEmail">
                    <p><span><FontAwesomeIcon className="profileIcon" icon={faEnvelopeOpen} /></span>{clinicInfo.clinicEmail}</p>
                  </section>
                  
                <div className="LineBreak ProfileLine"></div>

                <section className="UserInfoItems">
                  <h4 className="profileHeader">Primary Contact:</h4>
                  <div className="DisplayedInfo">
                    <p>{clinicUserInfo.userFirst} {clinicUserInfo.userLast}</p>
                    {this.state.editNameShow ? null : 
                    <button className="ProfileButton" onClick={this.toggleNameEdit}>{pencil}</button>
                  }
                  </div>
                  

                  {this.state.editNameShow ?
                  <form 
                    className="EditInfoContainer NameEditForms"
                    onSubmit={this.submitNameEdit}>
                      <div className="NameInputContainer">
                        <label htmlFor="firstName">First Name</label>
                        <input
                        required
                        type="text"
                        name="firstName"
                        id="firstName"
                        placeholder={clinicUserInfo.userFirst}
                        onChange={this.handleChange} 
                        maxLength="30" />
                        <label htmlFor="lastName">Last Name</label>
                        <input
                        required
                        type="text"
                        id="editLastName"
                        placeholder={clinicUserInfo.userLast}
                        onChange={this.handleChange} 
                        maxLength="30" />
                      </div>
                    
                    <div className="EditFormButtonsContainer">
                      <button className="ProfileSaveButton" type="submit"><FontAwesomeIcon className="CheckButton" icon={faCheck} /><span> </span>Save Changes</button>
                      <button className="ProfileCancelButton" onClick={this.cancelNameEdit}><FontAwesomeIcon className="CancelButton" icon={faTimes} /><span> </span>Cancel</button>
                    </div>
                  </form>
                  : null }
                  
                </section>

                <section className="UserInfoItems UserEmail">
                  <div className="DisplayedInfo">
                    <p>{clinicUserInfo.userEmail}</p>
                    {this.state.editEmailShow ? null :
                  <button className="ProfileButton" onClick={this.toggleEmailEdit}>{pencil}</button>
                  }
                  </div>
                  
                  {this.state.editEmailShow ?
                  <form 
                  className="EditInfoContainer EditEmail"
                  onSubmit={this.submitEmailEdit}>
                    <label htmlFor="personalEmail">Personal Email Address</label>
                    <input
                    required
                    type="email"
                    id="editEmail"
                    placeholder={clinicUserInfo.userEmail}
                    onChange={this.handleChange} />
                    <div className="EditFormButtonsContainer">
                      <button className="ProfileSaveButton" type="submit"><FontAwesomeIcon className="CheckButton" icon={faCheck} /><span> </span>Save Changes</button>
                      <button className="ProfileCancelButton" onClick={this.cancelEmailEdit}><FontAwesomeIcon className="CancelButton" icon={faTimes} /><span> </span>Cancel</button>
                    </div>
                  </form>
                  : null }
                  
                  
                </section>

                <section className="UserInfoItems UserPhoneNumber">
                  <div className="DisplayedInfo">
                    <p>({clinicUserInfo.userPhoneNumberAreaCode}) {clinicUserInfo.userPhoneNumberFirstThree}-{clinicUserInfo.userPhoneNumberLastFour}</p>
                  {this.state.editNumberShow ? null :
                  <button className="ProfileButton" onClick={this.toggleNumberEdit}>{pencil}</button>
                  }
                  </div>
                  
                  {this.state.editNumberShow ?
                  <form 
                  className="EditInfoContainer EditPhoneNumber"
                  onSubmit={this.submitNumberEdit}>
                    <div className="InputContainer">
                      <label htmlFor="areaCode">Area Code</label>
                      <input
                      required
                      type="text"
                      id="editAreaCode"
                      placeholder={clinicUserInfo.userPhoneNumberAreaCode}
                      onChange={this.handleChange} 
                      // pattern="([0-9]|[0-9]|[0-9])"
                      maxLength="3" />
                      <label htmlFor="personalEmail">Phone number: First three numbers</label>
                      <input
                      required
                      type="text"
                      id="editFirstThreeDigits"
                      placeholder={clinicUserInfo.userPhoneNumberFirstThree}
                      onChange={this.handleChange}  
                      // pattern="([0-9]|[0-9]|[0-9])"
                      maxLength="3" />
                      <p>-</p>
                      <label htmlFor="personalEmail">Phone number: Last Four numbers</label>
                      <input
                      required
                      type="text"
                      id="editLastFourDigits"
                      placeholder={clinicUserInfo.userPhoneNumberLastFour}
                      onChange={this.handleChange} 
                      // pattern="([0-9]|[0-9]|[0-9])"
                      maxLength="4" />
                    </div>
                   
                    <div className="EditFormButtonsContainer">
                      <button className="ProfileSaveButton" type="submit"><FontAwesomeIcon className="CheckButton" icon={faCheck} /><span> </span>Save Changes</button>
                      <button className="ProfileCancelButton" onClick={this.cancelNumberEdit}><FontAwesomeIcon className="CancelButton" icon={faTimes} /><span> </span>Cancel</button>
                    </div>
                  </form>
                  : null }
                </section>

                {/* <div className="profileButtons">
                  {this.state.editButtonShow ?
                  <button className="ProfileButton" onClick={this.toggleEdit}>Edit profile</button>
                  : null }

                  {this.state.editFormShow ?
                  <button className="ProfileButton" onClick={this.submit}>Save Changes</button>
                  : null }
                  {this.state.editFormShow ?
                  <button className="ProfileButton" onClick={this.cancel}>Cancel</button>
                  : null }
                </div> */}
              </div>
              
            </div>
          </div>
        </main>
      </div>
    )
  }
}

export default Home;